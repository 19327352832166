import React from 'react';
import { NavLink } from 'react-router-dom';
const Common=(props)=>{
    return(
        <>
     <section id="header" className='main'>
     <div className="container-fluid">
    <div className="row d-flex">
        <div className="col-10 mx-auto">
            <div className="row mx-auto">
            <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1">
    <h1>{props.name}<br /> <strong className='brand-name'>Satya-Devop</strong></h1>
    <h2 className="my-3 para">
        we are valuable tallented persons who can provide well service.
    </h2>
    <div className="mt-4">
        <NavLink to={props.visit} className='btn-get-started'>{props.btnName}</NavLink>
    </div>
    </div>
    <div className="col-lg-6 order-1 order-lg-2 header-img ">
        <img className='img-fluid animated' src={props.image} style={{height:'300px'}} alt="home img" />
    </div>
            </div>
    
         </div>
    </div>
     </div>
     </section>
    </>
    )
};
export default Common;